import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/norma-iso-bezpieczenstwo.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";

export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "wdrozenie-szbi.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Wdrozenieiso27001 = ({ data }) => {
  return (
    <Artykul
      title="Bezpieczeństwo informacji w firmie Questy - Norma ISO 27001"
      keywords={["norma ISO 27001"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Bezpieczeństwo informacji w firmie Questy - Norma ISO 27001"
      metaTitle="Bezpieczeństwo informacji w firmie Questy - Norma ISO 27001"
      metaDescription="Questy wdraża system zarządzania bezpieczeństwem informacji zgodny z normą ISO 27001 ✅ Na czym polega norma ISO 27001? 
      Zalety normy ISO 27001"
    >
      <h2
        style={{
          textAlign: "left",
        }}
      >
        Bezpieczeństwo informacji w systemach CRM, e-Commerce B2B i innych
      </h2>
      <p>
        W obecnych czasach informacja stanowi jeden z najważniejszych zasobów
        praktycznie każdej organizacji. Prawidłowe nią zarządzanie i zadbanie o
        jej bezpieczeństwo są kwestiami fundamentalnymi dla przedsiębiorstw
        liczących na rozwój na mocno konkurencyjnym rynku.{" "}
        <strong>
          Norma ISO 27001 została opracowana po to, by ułatwić firmom zadbanie o
          bezpieczeństwo danych należących do nich oraz ich klientów.
        </strong>
      </p>

      <p>
        Firma Questy podziela zdanie, że ​​bezpieczeństwo danych jest
        strategicznie ważne dla biznesu dowolnej wielkości, dlatego też
        przystąpiła do procesu certyfikacji normy ISO 27001.
      </p>
      <br />
      <h2>Czym jest ISO 27001?</h2>
      <p>
        Norma ISO 27001: 2013 to powszechnie uznany, międzynarodowy standard,
        opisujący jak wdrożyć certyfikowany System Zarządzania Bezpieczeństwem
        Informacji (SZBI). Standard pozwala zapewnić bezpieczeństwo wszelkich
        danych finansowych i poufnych,{" "}
        <strong>
          ograniczając w ten sposób możliwość uzyskania do nich nielegalnego lub
          nieuprawnionego dostępu.
        </strong>
      </p>
      <br />
      <h2>Cel wdrożenia ISO 27001</h2>
      <p>
        Najważniejszym celem, dla którego wdrażana jest norma ISO 27001, jest
        chęć możliwie jak najlepszego zabezpieczenia informacji będących w
        posiadaniu firmy. Można mieć pewność, że przedsiębiorstwo dysponujące
        tym certyfikatem wykazuje pełne zaangażowanie w kwestii bezpieczeństwa
        danych, a procedury w nim obowiązujące są zgodne z najlepszymi
        światowymi praktykami.
      </p>
      <br />
      <h2>Przebieg wdrożenia</h2>
      <p>
        Ścieżka implementacji Systemu Zarządzania Bezpieczeństwem Informacji
        zgodnego z ISO/IEC 27001 wygląda zazwyczaj następująco:
      </p>

      <p>
        <ul>
          <li>przeprowadzenie wstępnego audytu,</li>
          <li>
            analiza organizacji oraz identyfikacja jej obszarów i procesów,
            które mają być objęte systemem,
          </li>
          <li>
            zidentyfikowanie potencjalnych zagrożeń, analiza i ocena ryzyka,
          </li>
          <li>
            weryfikacja istniejących procedur systemowych oraz integracja ISO
            27001 z pozostałymi systemami zarządzania,
          </li>
          <li>
            szkolenia pracowników oraz kadry zarządzającej dotyczące wymagań
            normy ISO 27001,
          </li>
          <li>audyt wewnętrzny,</li>
          <li>
            certyfikacja, która potwierdza skuteczność wdrożonego systemu.
          </li>
        </ul>
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Wdrożenie normy ISO 27001."]}
      />
      <br />
      <h2>Zalety wdrożenia ISO 27001</h2>
      <p>
        Co wdrożenie Systemu Zarządzania Bezpieczeństwem Informacji zgodnego z
        ISO 27001 oznacza dla naszych klientów? Pośród najważniejszych zalet
        implementacji tego rozwiązania należy wymienić:
      </p>

      <p>
        <ul>
          <li>gwarancję odpowiedniej ochrony wszystkich informacji,</li>
          <li>
            zminimalizowanie ryzyka wystąpienia zdarzeń dotyczących
            bezpieczeństwa danych,
          </li>
          <li>redukcję do minimum ryzyka utraty lub przejęcia danych,</li>
          <li>
            szybką identyfikację problemów z bezpieczeństwem i skuteczne ich
            usunięcie dzięki wypracowanym procedurom.
          </li>
        </ul>
      </p>
      <br />
      <h2>Questy wdraża normę ISO 27001</h2>
      <p>
        Otrzymanie certyfikatu ISO 27001 to osiągnięcie, które wymaga od
        organizacji dużo wysiłku i pełnego zaangażowania. Bez wahania jednak
        podjęliśmy to wyzwanie, ponieważ taki{" "}
        <strong>
          certyfikat jest wyraźnym podkreśleniem tego, jak poważnie traktujemy
          bezpieczeństwo informacji naszych kontrahentów.
        </strong>
      </p>

      <p>
        Celem wdrożenia normy ISO 27001 w Questy jest opracowanie własnej
        metodologii oceny ryzyka i stosowania środków ochronnych oraz utworzenia
        kompleksowego zestawu zasad i procedur zapewniających bezpieczeństwo
        informacji w różnych obszarach. Docelowo{" "}
        <strong>
          nasz system SZBI będzie też stale aktualizowany i udoskonalany
        </strong>
        .
      </p>
    </Artykul>
  );
};

export default Wdrozenieiso27001;
